import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import DropdownList from '@/ui-components/dropdownListBx/DropdownList.vue';
import { DropdownListItem } from '@/ui-components/dropdownListBx/types';
import { getModule } from 'vuex-module-decorators';
import { GlobalModule } from '@/store/global/globalModule';
import store from '@/store';
import APP_UTILITIES from '@/utilities/commonFunctions';
@Component({
  components: {
    VueAdsPagination,
    VueAdsPageButton,
    DropdownList,
  }
})
export default class PaginationComponent extends Vue {
  private objScreenText: ScreenText = new ScreenText();
  loading: boolean = false;
  isLoaded: boolean = false;
  page: number = 0;
  goPage: string = '';
  isError: boolean = false;
  lastPaginationNo: number = 0;
  public propsData: any;
  public isMobileView: boolean = false;
  getGlobalState = getModule(GlobalModule, store);

  @Prop()
  total_items!: 0;

  @Prop()
  isShow!: false;

  @Prop()
  isMobile!: true;

  @Prop()
  isMobileForAPiCall!: false;

  @Prop()
  noOfRowsForMobile!: 10;

  @Prop()
  pageChangeUp!: any;

  @Prop()
  type!: any;

  @Prop()
  initialPage!: number;

  @Prop()
  initialPageTrue!: boolean;

  @Prop()
  itemsPerPage: number | undefined;

  /**
   * Show items per page selector. Default is `false`
   */
  @Prop({ default: false })
  showItemsPerPageSelector!: boolean;

  /**
   * Items per page selector options. Default options are `[25, 50, 100, 200]`
   */
  @Prop({ default: () => [25, 50, 100, 200] })
  itemsPerPageSelectorOptions!: number[];

  /**
   * Gets the options for the items per page dropdown.
   * @returns {Object | null} The options for the items per page dropdown. Returns null if `showItemsPerPageSelector` prop is set to false
   */
  get itemsPerPageDropdownOptions(): Object | null {
    if (this.showItemsPerPageSelector) {
      return {
        id: 0,
        disable: false,
        error: false,
        showSelectLabel: false,
        label: '',
        value: `${this.itemsPerPage}`,
        required: false,
        search: false,
        placeholder: '',
        dropdownList: this.itemsPerPageSelectorOptions.map((option, idx) => ({
          id: option,
          type: option.toString(),
          value: option.toString(),
          default: idx === 0 // First item is default
        }))
      };
    }
    return null;
  }

  /**
   * Handles the change event when one of the options of the dropdown component is selected.
   * Emits an `itemsPerPageChanged` event with the selected item value.
   *
   * @param item - The selected dropdown item.
   */
  onItemPerPageSelectorChange(item: DropdownListItem): void {
    this.$emit('itemsPerPageChanged', Number(item.value));
  }

  created() {
    this.isMobileView = APP_UTILITIES.mobileAndTabletCheck();
  }

  @Watch('initialPage', { deep: true, immediate: true })
  changeInitialPage(val: number) {
    this.page = val;
  }

  @Watch('pageChangeUp', { deep: true, immediate: true })
  changePageUp(val: any) {
    if (val && (this.page !== val.pageIndex || val.pageChange) && val.isPageChange) {
      this.page = val.pageIndex - 1;
      this.rangeChange(14, 28);
    }
  }

  public getScreenText(key: string): string {
    return this.objScreenText.getScreenText(key);
  }

  pageChange(page: number) {
    this.page = page;
  }

  rangeChange(start: number, end: number) {
    setTimeout(() => {
      if (this.initialPageTrue) {
        this.resetGlobalPageIndex();
      }

      if (this.getGlobalState.resetPageIndex) {
        this.page = 0;
        this.isLoaded = false;
        this.$store.dispatch('globalModule/resetPageCount', false);
      }

      this.goPage = '';
      if (this.page > 0) {
        this.isLoaded = true;
        this.$emit(APP_CONST.PAGE, this.page + 1);
      }
      else if ((this.isLoaded) && (this.page === 0)) {
        this.$emit(APP_CONST.PAGE, this.page + 1);
      }
      this.isError = false;
    }, 100);

  }

  pageChangeScroll() {
    window.scrollTo(0, 0);
  }

  goToPageHandler(e: any) {
    e.preventDefault();
    e.target.blur();
    if (this.goPage != '') {
      this.goPage = Number.parseFloat(this.goPage).toFixed();
      if (Number(this.goPage) <= this.lastPaginationNo && Number(this.goPage) > 0 && !isNaN(Number(this.goPage))) {
        this.isLoaded = false;
        this.isError = false;
        this.page = Number(this.goPage) - 1;
        if (Number(this.goPage) === 1) {
          this.isLoaded = true;
        }
      }
      else {
        this.isError = true;
      }
    }
    else {
      this.isError = true;
    }

  }
  checkSpecialCharecter(value: string) {
    const values = APP_UTILITIES.removeSpecialCharacters(value);
    this.goPage = values;
    return values;
  }
  filterKey(e: any) {
    const key = e.key;
    if (key === '.') {
      return e.preventDefault();
    }
  }
  findLastButton(props: any) {
    this.propsData = [...props.buttons];
    const paginationButton: any = [...props.buttons];
    paginationButton.sort(function (a: any, b: any) {
      return a.page - b.page;
    });
    this.lastPaginationNo = Number(paginationButton[paginationButton.length - 1].html);
    if (Number.isNaN(Number(paginationButton[paginationButton.length - 1].html))) {
      this.lastPaginationNo = Number(paginationButton[paginationButton.length - 2].html);
    }
    if (this.propsData.length >= 7) {
      let count = 0;
      for (let i = 0; i < this.propsData.length; i++) {
        if (this.propsData[i].page === '...') {
          count += 1;
        }
      }
      this.SetPropsData(count);
    }

  }

  private SetPropsData(count: number) {
    if (count == 2) {
      this.propsData[1] = { page: this.propsData[3].page - 2, active: false, disabled: false, html: (this.propsData[3].page - 1).toString(), title: '' };
      this.propsData[2] = { page: this.propsData[3].page - 1, active: false, disabled: false, html: (this.propsData[3].page).toString(), title: '' };
    }
    if (count == 0) {
      if (this.propsData[4].active === false && (this.propsData[5].active === false)) {
        this.propsData[4] = { active: false, disabled: true, html: '...', loading: false, page: '...', title: '' };
      }
      else if (this.propsData[4].active === true || this.propsData[5].active === true) {
        this.propsData[2] = { active: false, disabled: true, html: '...', loading: false, page: '...', title: '' };
      }
    }
  }

  private resetGlobalPageIndex() {
    this.$store.dispatch('globalModule/resetPageCount', true);
  }
}
